.quote_div{
    background-color: white;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    text-align: center;
    align-items: center;
    letter-spacing: .01spx;
    background-image: url(quoteimg.png);
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;    
}
.quote_div div{
    max-width: 500px;
    margin: 100px auto;
}

.author_name{
    font-weight: 400;
    font-size: 19px;
}


.home_carousel-container {
    position: relative;
    height: 90vh;
    &.loading {
        background-image: url('./quoteimg.png');
        background-size: contain;
        background-position-x: center;
        background-repeat: no-repeat; 
        margin-top: 50px;  
    }
}

.home_image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    margin: 0;
    padding: 0;
}

.home_background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    filter: blur(5px);
    z-index: -1;
}
  
  