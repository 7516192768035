.Footer {
    padding: 1.5rem 0;
    background-color: #202020;
    color: white;
}
.Footer h5,.Footer h3 {
    color: #1A859B;
    font-weight: 500;
}



.ft-1 h3 {
    font-weight: 500;
    letter-spacing: .1px;
}  
.ft-1 p {
    width: 350px;
    color: rgb(219, 218, 218);
}
.footer-icons a {
    text-decoration: none;
    padding: 10px;
    background: #e1e1e1;
    color: #1A859B;
    margin: 0 0.5rem;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
}  
.footer-icons a:hover {
    background: #1A859B;
    color: #fff;
    cursor: pointer;
}



.ft-2 ul {
    list-style: none;
    padding-left: 0;
}
.ft-2 ul a {
    text-decoration: none;
    color: #c5c4c4;
    font-size: 14px;
    padding: .5px 0;
    transition: .2s ease-in-out;
}
.ft-2 ul a:hover{
    color: #fff;   
    padding: .5px 1px;
}  
  
  
  
.ft-3 p {
    font-weight: 300;
    padding: .5px 0;
    font-size: 1.06rem;
    font-size: 14px;
    color: #c5c4c4;
}
.ft-3 p:hover {
    color: white;
    font-weight: 300;
    padding: .5px 0;
    font-size: 1.06rem;
    font-size: 14px;
}  
.ft-3 i {
    padding-right: 10px;
}