.forgot_div {
  display: grid;
  place-items: center;
  background-color: #333333;
}

.forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.form-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.otp-input {
  width: 30px;
  height: 30px;
  margin: 0 5px; /* Adjusted margin */
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  text-align: center;
  font-weight: bold;
}

.otp-text {
  display: block;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333333;
}

.submit-button {
  margin-top: 30px;
}

/* Add any additional styles as needed */
