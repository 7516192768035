.profile_container {
    padding: 20px;
    margin-top: 20px;
    min-height: 70vh;
  }
  
  .profile_title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #1D617A;
    letter-spacing: 1px;
  }

  .profile_details_title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #1D617A;
    text-decoration: underline;
  }

  .profile_subtitle {
    font-weight: bold;
    margin-right: 5px;
  }
  
  .wave {
    animation: wave 1.5s infinite;
    display: inline-block;
  }
  
  @keyframes wave {
    0%, 100% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(10deg);
    }
  }
  

  .member_certificate{
    margin-top: 20px;
  }
  .member-certificate-download{
    margin-top: 8px;
    height: 40px;
    width: 100px;
    background-color: white;
    border: 2px solid #1D617A;
    border-radius: 5px;
    transition: 300ms ease;
    text-decoration: none;
    color: #1D617A;
  }
  .member-certificate-download:hover{
    background-color: #1D617A;
    color: white;
  }