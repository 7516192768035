.Team_Title {
  margin: 20px;
  text-align: center;
}

.member_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1100px;
}

.member_col {
  display: flex;
  justify-content: space-evenly;
  width: 100%x;
  margin: 10px 0; /* Added margin */
}

.member_card {
  width: 250px;
  height: 300px;
  transition: box-shadow 1s, transform 0.5s ease;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0.2, 0.1, 0.2, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.member_card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transform: scale(1.05);
}

.member_card_img {
  width: 100%; /* Adjusted width to 100% */
  height: 200px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.name {
  font-weight: 600;
  font-size: large;
  margin-top: 10px;
  text-align: center;
}

.position {
  font-weight: 500;
  font-size: medium;
  text-align: center;
}

@media (max-width: 768px) {
  .member_col {
    width: 100%; /* Full width on smaller screens */
    margin: 10px 0;
  }

  /* Hide the scrollbar for smaller screens */
  .MuiTabs-scroller {
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  .MuiTabs-flexContainer {
    flex-wrap: nowrap;
  }

  .MuiTabs-root {
    overflow: hidden;
  }

  /* Set variant to fullWidth on smaller screens */
  .MuiTabs-root {
    flex-wrap: nowrap;
    background-color: #f8f8f8;
  }

  .MuiTab-root {
    text-transform: none;
    font-size: 15px;
    min-width: auto;
    margin: 0 5px;
    color: rgba(0, 0, 0, 0.7);
  }

  .MuiTab-textColorPrimary {
    color: rgba(0, 0, 0, 0.7);
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #000; /* Active tab color */
  }

  .MuiTabs-indicator {
    display: none; /* Hide the indicator */
  }
}
